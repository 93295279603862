import { PiletScopedLogger } from '@sharefiledev/sharefile-appshell';
const loggerNotSetup = () => {
	throw new Error('Logger not setup');
};
let logger: PiletScopedLogger = {
	logError: loggerNotSetup,
	logInfo: loggerNotSetup,
	setLoggingTags: loggerNotSetup,
};
export function setLogger(piletLogger: PiletScopedLogger) {
	logger = piletLogger;
}
export function getLogger() {
	return logger;
}
