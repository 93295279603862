import * as React from 'react';
import { Api } from '@citrite/sf-api';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AsyncLazyThreatDashboard } from './extensions';
import { setLogger } from './logger';

export function setup(app: PiletApi) {
	setLogger(app.sf.getLogger());
	app.sf.registerSfApiConfigHandler(Api.configure);

	app.registerExtension(
		'urn:sfextslot:sharefile-appshell:user-activity-history',
		({ params }) => {
			return <AsyncLazyThreatDashboard {...params} piletApi={app} />;
		}
	);
}
