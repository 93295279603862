import React from 'react';
import { Skeleton } from 'antd';
import { ThreatDashboardProps } from './types';

const LazyThreatDashboard = React.lazy(() => import('./ThreatDashboard'));

export const AsyncLazyThreatDashboard: React.FC<ThreatDashboardProps> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyThreatDashboard {...props} />
	</React.Suspense>
);
